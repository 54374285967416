p.rodapePrimeiroItem {
    text-align: left;
}

p.rodapeUltimoItem {
    text-align: right;
}

@media (max-width: 600px) {
    p.rodapePrimeiroItem, p.rodapeUltimoItem {
        text-align: center;
    }
    div.blocoVideo {
        padding-right: 0px;
        padding-left: 0px;
    }
 }